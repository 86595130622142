import React from 'react'
import { SelectInputStyled } from '@edulastic/common'
import { Row, Col, Select, Tooltip } from 'antd'
import { testTypes as testTypesConstants, roleuser } from '@edulastic/constants'
import { IconInfo } from '@edulastic/icons'
import { lightGrey9 } from '@edulastic/colors'
import { StyledRadioGroup, Description, StyledRadioBtn } from './styled'
import {
  getDefaultTestTypeForGroup,
  testTypeGroups,
  getPermissionWarningType,
} from './helper'
import AdminCoAuthorSelection from './AdminCoAuthorSelection'

const { Option } = Select

const { TEST_TYPE_SURVEY, TEST_TYPES } = testTypesConstants

const AdvancedTestTypeSelection = ({
  disabled,
  updateTestData,
  testType,
  testTypes,
  userRole,
  testId,
  adminCoAuthors,
  testStatus,
  userPermissions,
}) => {
  const currentTestTypeGroup = TEST_TYPES.COMMON.includes(testType)
    ? testTypeGroups.COMMON
    : testTypeGroups.INDIVIDUAL

  return (
    <>
      <Description>
        Choose test type based on purpose of the test. Individual assignment is
        for individual use by the user, while Common assignment is conducted
        across multiple teachers, such as common tests. Any edits made to Common
        test will update across all assigned classes across teachers.
      </Description>

      <Row style={{ marginTop: '20px' }}>
        <Col span={6}>
          <span style={{ fontWeight: 600 }}>This test will be used for</span>
        </Col>
        <Col span={18}>
          <StyledRadioGroup
            disabled={disabled || testType === TEST_TYPE_SURVEY}
            onChange={(e) =>
              updateTestData('testType')(
                getDefaultTestTypeForGroup(e.target.value, testTypes)
              )
            }
            value={currentTestTypeGroup}
          >
            <StyledRadioBtn
              value={testTypeGroups.INDIVIDUAL}
              data-cy={`${testTypeGroups.INDIVIDUAL}Assignment`}
              key={testTypeGroups.INDIVIDUAL}
              textTransform="none"
            >
              <div
                style={{
                  display: 'flex',
                  width: '400px',
                  alignItems: 'center',
                }}
              >
                Individual Assignment
                <div style={{ width: '40px' }}>
                  <Tooltip title="This test is intended for individual use, not as a shared assignment. Any edits to a live test will be reflected only on the classes of the editing teacher.">
                    <IconInfo
                      color={lightGrey9}
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        right: '-10px',
                      }}
                    />
                  </Tooltip>
                </div>
                {currentTestTypeGroup === testTypeGroups.INDIVIDUAL && (
                  <SelectInputStyled
                    value={testType}
                    disabled={disabled || testType === TEST_TYPE_SURVEY}
                    onChange={updateTestData('testType')}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    data-cy="testType"
                    style={{ width: '200px' }}
                  >
                    {Object.keys(testTypes).map((key) =>
                      !TEST_TYPES.COMMON.includes(key) ? (
                        <Option key={key} value={key}>
                          {testTypes[key]}
                        </Option>
                      ) : null
                    )}
                  </SelectInputStyled>
                )}
              </div>
            </StyledRadioBtn>
            <StyledRadioBtn
              value={testTypeGroups.COMMON}
              data-cy={`${testTypeGroups.COMMON}Assignment`}
              key={testTypeGroups.COMMON}
              textTransform="none"
            >
              <div
                style={{
                  display: 'flex',
                  width: '400px',
                  alignItems: 'center',
                }}
              >
                Common Assignment
                <div style={{ width: '40px' }}>
                  <Tooltip title="This test serves as a shared/common assessment. Any edits made to this live test will update across all assigned classes across teachers in current year within the district. Examples include district-wide common tests, school-wide assessments, or collaborative shared assignments among teachers or PLC groups.">
                    <IconInfo
                      color={lightGrey9}
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        right: '-10px',
                      }}
                    />
                  </Tooltip>
                </div>
                {currentTestTypeGroup === testTypeGroups.COMMON && (
                  <SelectInputStyled
                    value={testType}
                    disabled={disabled || testType === TEST_TYPE_SURVEY}
                    onChange={updateTestData('testType')}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    data-cy="testType"
                    style={{ width: '200px' }}
                  >
                    {Object.keys(testTypes).map((key) =>
                      TEST_TYPES.COMMON.includes(key) ? (
                        <Option key={key} value={key}>
                          {testTypes[key]}
                        </Option>
                      ) : null
                    )}
                  </SelectInputStyled>
                )}
              </div>
            </StyledRadioBtn>
          </StyledRadioGroup>
          {currentTestTypeGroup === testTypeGroups.COMMON &&
            userRole === roleuser.TEACHER &&
            testStatus === 'draft' &&
            !disabled &&
            testId && (
              <Row style={{ marginTop: '20px' }}>
                <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                  Add an Admin as co-author (Optional)
                </div>
                <AdminCoAuthorSelection
                  testId={testId}
                  adminCoAuthors={adminCoAuthors}
                  showPermissionWarning={getPermissionWarningType(
                    userPermissions,
                    testType
                  )}
                />
              </Row>
            )}
        </Col>
      </Row>
    </>
  )
}

export default AdvancedTestTypeSelection
