import { accessibilityColors, themeColorBlue, white } from '@edulastic/colors'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ActionItemsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  font-weight: 600;
  color: ${accessibilityColors.theme.greenDarker};
  border-radius: 5px;
  border: 1px solid ${accessibilityColors.theme.greenDarker};
  padding: 2px 12px;
  &:hover {
    background: ${themeColorBlue};
    border-color: ${themeColorBlue};
    color: ${white};
  }
`

export const NewClassButton = styled(Button)`
  border: none !important;
  color: ${accessibilityColors.theme.greenDarker};
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
  box-shadow: none;
  background-color: transparent;
  &:hover,
  :focus {
    color: ${accessibilityColors.theme.greenDarker};
    background-color: transparent;
  }
`
