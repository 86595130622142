/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconCommentFilled = (props) => (
  <SVG
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33335 3.33317H16.6667V13.3332H4.30835L3.33335 14.3082V3.33317ZM3.33335 1.6665C2.41669 1.6665 1.67502 2.4165 1.67502 3.33317L1.66669 18.3332L5.00002 14.9998H16.6667C17.5834 14.9998 18.3334 14.2498 18.3334 13.3332V3.33317C18.3334 2.4165 17.5834 1.6665 16.6667 1.6665H3.33335ZM5.00002 9.99984H15V11.6665H5.00002V9.99984ZM5.00002 7.49984H15V9.1665H5.00002V7.49984ZM5.00002 4.99984H15V6.6665H5.00002V4.99984Z"
      fill="#555555"
    />
  </SVG>
)

export default withIconStyles(IconCommentFilled)
