import styled from 'styled-components'
import { StyledTable, StyledTableButton } from '../../../../common/styled'

export const StyledTeacherTable = styled(StyledTable)``

export const StyledMaskButton = styled(StyledTableButton)`
  svg {
    transform: scale(2);
    margin-right: 5px;
  }
`

export const CheckBoxSubLabel = styled.div`
  margin-left: 30px;
  font-weight: 400;
`

export const EnableDisableContainer = styled.div`
  margin-left: 30px;
  margin-top: -10px;
`
