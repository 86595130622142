import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { segmentApi } from '@edulastic/api'
import { Icon } from 'antd'
import { accessibilityColors } from '@edulastic/colors'
import { roleuser } from '@edulastic/constants'
import { ActionItemsContainer, NewClassButton, StyledLink } from './styled'
import ReSyncClasses from '../../../../Showcase/components/Myclasses/components/Classes/ReSyncClasses'
import {
  getCanvasAllowedInstitutionPoliciesSelector,
  getCleverLibraryUserSelector,
  getGoogleAllowedInstitionPoliciesSelector,
  getManualEnrollmentAllowedSelector,
  getUser,
} from '../../../../../../src/selectors/user'
import {
  getIsCleverClassExists,
  getProviderNames,
  setShowClassCreationModalAction,
} from '../../../../../ducks'
import ClassView from './ClassView'
import AuthorCompleteSignupButton from '../../../../../../../common/components/AuthorCompleteSignupButton'
import { setCreateClassTypeDetailsAction } from '../../../../../../ManageClass/ducks'

const ActionItems = ({
  user,
  googleAllowedInstitutions,
  isCleverUser,
  canvasAllowedInstitution,
  isCleverDistrict,
  studentsList,
  groups,
  history,
  providerNames,
  isCleverClassExists,
  setShowClassCreationModal,
  setCreateClassTypeDetails,
  manualEnrollmentAllowed,
}) => {
  const isClassLink =
    studentsList && studentsList.filter((id) => id?.atlasId).length > 0
  const isManualEnrollmentAllowed =
    user?.role === roleuser.TEACHER ? manualEnrollmentAllowed : true
  return (
    <ActionItemsContainer>
      <ClassView />
      <ReSyncClasses
        user={user}
        classGroups={groups}
        isClassLink={isClassLink}
        googleAllowedInstitutions={googleAllowedInstitutions}
        enableCleverSync={isCleverUser}
        canvasAllowedInstitution={canvasAllowedInstitution}
        isCleverDistrict={isCleverDistrict}
        history={history}
        providerNames={providerNames}
        isCleverClassExists={isCleverClassExists}
        textColor={accessibilityColors.theme.greenDarker}
        text="Sync Classes"
      />
      {/* Create class */}
      {isManualEnrollmentAllowed && (
        <AuthorCompleteSignupButton
          renderButton={(handleClick) => (
            <NewClassButton data-cy="createNewClass" onClick={handleClick}>
              <Icon type="plus-circle" />
              Create Class
            </NewClassButton>
          )}
          onClick={() => {
            setShowClassCreationModal(true)
            setCreateClassTypeDetails({ type: 'class' })
          }}
          triggerSource="Create Class"
        />
      )}
      <StyledLink
        data-cy="seeAllClasses"
        to="/author/manageClass"
        onClick={() =>
          segmentApi.genericEventTrack('Dashboard:Classes:SeeAll', {
            source: 'Dashboard',
          })
        }
      >
        See All
      </StyledLink>
    </ActionItemsContainer>
  )
}

export default connect(
  (state) => ({
    user: getUser(state),
    studentsList: get(state, 'manageClass.studentsList', {}),
    googleAllowedInstitutions: getGoogleAllowedInstitionPoliciesSelector(state),
    isCleverUser: getCleverLibraryUserSelector(state),
    canvasAllowedInstitution: getCanvasAllowedInstitutionPoliciesSelector(
      state
    ),
    providerNames: getProviderNames(state),
    isCleverClassExists: getIsCleverClassExists(state),
    isCleverDistrict: get(state, 'user.user.orgData.isCleverDistrict', false),
    manualEnrollmentAllowed: getManualEnrollmentAllowedSelector(state),
  }),
  {
    setShowClassCreationModal: setShowClassCreationModalAction,
    setCreateClassTypeDetails: setCreateClassTypeDetailsAction,
  }
)(ActionItems)
